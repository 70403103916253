// PREFERENCE
export const OPTION = {
  DISABLE: false,
  ENABLE: true
}

export const PREFERENCE_SECTION = {
  CUBOID: 'cuboid',
  POINTS: 'points',
  PROJECTION: 'projection',
  ROI: 'roi',
  ROI_BOX: 'roiBox',
  SEGMENTATION: 'segmentation',
  SELECTION: 'selection'
}
export const PREFERENCE_KEY = {
  CUBOID: { FILL_POINTS_COLOR: 'fillPointsColor' },
  POINTS: {
    COLOR: 'color',
    SIZE: 'size'
  },
  PROJECTION: {
    IMAGE_BRIGHTNESS: 'imageBrightness',
    VIEW_TYPE: 'viewType',
    VISIBLE: 'visible'
  },
  ROI: { AOV: 'aov', RADIUS: 'radius', VISIBLE: 'visible' },
  ROI_BOX: { VISIBLE: 'visible' },
  SEGMENTATION: { BRUSH_SIZE: 'brushSize', MODE: 'mode' },
  SELECTION: { TRACKING_MODE: 'trackingMode' }
}
export const TOOL_NAME = {
  COMMENT: 'review_comment',
  CUBOID: '3d_bounding_box',
  OBJECT_MODE: 'object_mode',
  POLYGON: '3d_polygon',
  POLYLINE: '3d_polyline',
  RELATION: 'relation',
  ROI: 'roi',
  SEGMENTATION: '3d_segmentation'
}
// ERROR
export const ERROR = {
  SCREEN_RANGE: 'screen_range'
}
// VIEW
export const AXES_NAME = {
  X: 'x',
  Y: 'y',
  Z: 'z'
}
export const AXES_OF_3D = ['x', 'y', 'z']
export const VIEW_NAME = {
  FRONT: 'front',
  MAIN: 'main',
  SIDE: 'side',
  TOP: 'top'
}

export const PROJECTION_VIEW_TYPE = {
  MULTI: 'multi',
  SINGLE: 'single'
}
export const VIEW_TYPES = new Set(['front', 'main', 'side', 'top'])
export const SUBVIEW_TYPES = new Set(['front', 'side', 'top'])
// SUBVIEW
export const SUB_D = {
  [VIEW_NAME.FRONT]: {
    d: AXES_NAME.X,
    h: AXES_NAME.Z,
    w: AXES_NAME.Y
  },
  [VIEW_NAME.SIDE]: {
    d: AXES_NAME.Y,
    h: AXES_NAME.Z,
    w: AXES_NAME.X
  },
  [VIEW_NAME.TOP]: {
    d: AXES_NAME.Z,
    h: AXES_NAME.X,
    w: AXES_NAME.Y
  }
}
// REVIEW
export const REVIEW = {
  MODE: {
    CREATE: 'create',
    EDIT: 'edit'
  }
}

//= ================ CUBOID===================
export const CUBOID = {
  MODE: {
    CREATE: 'create',
    EDIT: 'edit'
  }
}

export const CUBOID_VERTICES = {
  BLB: {
    code: 'BLB',
    direction: {
      x: -1,
      y: 1,
      z: -1
    },
    name: 'back-left-bottom'
  },
  BLT: {
    code: 'BLT',
    direction: {
      x: -1,
      y: 1,
      z: 1
    },
    name: 'back-left-top'
  },
  BRB: {
    code: 'BRB',
    direction: {
      x: 1,
      y: 1,
      z: -1
    },
    name: 'back-right-bottom'
  },
  BRT: {
    code: 'BRT',
    direction: {
      x: 1,
      y: 1,
      z: 1
    },
    name: 'back-right-top'
  },
  FLB: {
    code: 'FLB',
    direction: {
      x: -1,
      y: -1,
      z: -1
    },
    name: 'front-left-bottom'
  },
  FLT: {
    code: 'FLT',
    direction: {
      x: -1,
      y: -1,
      z: 1
    },
    name: 'front-left-top'
  },
  FRB: {
    code: 'FRB',
    direction: {
      x: 1,
      y: -1,
      z: -1
    },
    name: 'front-right-bottom'
  },
  FRT: {
    code: 'FRT',
    direction: {
      x: 1,
      y: -1,
      z: 1
    },
    name: 'front-right-top'
  }
}

export const TRANSFORM_MODE = {
  ROTATE: 'rotate',
  SCALE: 'scale',
  TRANSLATE: 'translate'
}
TRANSFORM_MODE.DEFAULT = TRANSFORM_MODE.TRANSLATE

//= ================ SEGEMENTATION===================
export const SEGMENTATION = {
  COLOR_MODE: {
    CLASS: 'class',
    OBJECT: 'object'
  },
  MODE: {
    COLOR: 'color',
    ERASE: 'erase',
    SELECT: 'select'
  },
  SELECT_TYPE: {
    CIRCLE: 'circle',
    LASSO: 'lasso',
    RECT: 'rect'
  }
}

export const VIEW_MODE_CODE = {
  ALL_POINTS: 'all-points',
  ONLY_SEGMENTED_POINTS: 'segmented-points',
  ONLY_SELECTED_ANNOTATIONS_POINTS: 'selected-annotations-points',
  ONLY_UNSEGMENTED_POINTS: 'unsegmented-points'
}

export const VIEW_MODE_MESSAGE = {
  [VIEW_MODE_CODE.ALL_POINTS]: null,
  [VIEW_MODE_CODE.ONLY_SEGMENTED_POINTS]: 'Segmented points',
  [VIEW_MODE_CODE.ONLY_SELECTED_ANNOTATIONS_POINTS]:
    'Selected annotations points',
  [VIEW_MODE_CODE.ONLY_UNSEGMENTED_POINTS]: 'Unsegmented points'
}

export const VIEW_MODE_COLOR = {
  [VIEW_MODE_CODE.ALL_POINTS]: null,
  [VIEW_MODE_CODE.ONLY_SEGMENTED_POINTS]: '#fff',
  [VIEW_MODE_CODE.ONLY_SELECTED_ANNOTATIONS_POINTS]: '#fff',
  [VIEW_MODE_CODE.ONLY_UNSEGMENTED_POINTS]: '#fff'
}

// Direction scale factor index of position array on box mesh
export const DIRECTION_SCALE_INDEX = 75

export const DIRECTION = {
  BACK: 'back',
  DOWN: 'down',
  FRONT: 'front',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up'
}

export const OPPOSITE_DIRECTION = {
  [DIRECTION.BACK]: 'front',
  [DIRECTION.DOWN]: 'up',
  [DIRECTION.FRONT]: 'back',
  [DIRECTION.LEFT]: 'right',
  [DIRECTION.RIGHT]: 'left',
  [DIRECTION.UP]: 'down'
}
