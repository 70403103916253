import Empty from '@/app/shared/views/empty'

const annotatorRoutes = [
  {
    children: [
      {
        component: () => import('../core/components/main'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'annotator-labeler-mode',
        path: '/annotator/:project_category/:assignment_id'
      },
      {
        component: () => import('../core/components/main'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'annotator-reviewer-mode',
        path: '/annotator/:project_category/:assignment_id/reviewer'
      },
      {
        component: () => import('../core/components/main'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'annotator-ground-truth-mode',
        path: '/annotator/:project_category/:label_id/ground_truth'
      },
      {
        component: () => import('../core/components/main'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'annotator-calculation-template-mode',
        path: '/annotator/:project_category/:label_id/calculation_template'
      },
      {
        component: () => import('../core/components/main'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'annotator-manager-mode',
        path: '/annotator/:project_category/:assignment_id/manager'
      },
      {
        component: () => import('../../../diagnosis/pcd-calib-checker/app'),
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        name: 'diagnosis',
        path: '/diagnosis/pcd-calib-checker/:label_id'
      }
    ],
    component: Empty,
    name: 'empty',
    path: ''
  }
]

export default annotatorRoutes
